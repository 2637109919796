<template>
  <div class="pages">
    <div class="header">
      <img v-if="status==0" key="1" src="../../assets/images/pay-error.png" alt="" class="store-logo" />
      <img v-if="status==1" key="2" src="../../assets/images/pay-success.png" alt="" class="suc-logo" />
      <img v-if="status==2" key="3" src="../../assets/images/pay-loading.png" alt="" class="pay-loading" />
      <img v-if="status==3" key="4" src="../../assets/images/load-err.png" alt="" class="load-err" />

      <div class="status-text" v-if="status==0" key="5">授权失败，请重新扫码授权！</div>
      <div class="status-text" v-if="status==1" key="6">恭喜您，授权成功！</div>
      <div class="status-text" v-if="status==2" key="7">正在处理，请耐心等待！</div>
      <div class="load-text" v-if="status==3" key="8">哎呀，网络繁忙，查询超时！</div>
    </div>
    <!-- <van-button class="finish-btn" @click="zfbAuth" v-if="status==0">重新支付</van-button> -->
  </div>
</template>

<script>
  import axios from 'axios';
  import { Notify } from 'vant';
  // import { zfbAuthApi } from "@/request/api";
  export default {
    name:'',
    components: {},
    data() {
        return {
          status: 2, //1支付成功 0支付失败 2处理中 3查询超时

          app_auth_code: null,
          state: null,

          authInfo:null,
        };
    },
    created () {
      let that = this;
      //支付宝授权成功后回调
      that.app_auth_code = that.$route['query']['app_auth_code'];
      that.state = that.$route['query']['state'];
      if(that.app_auth_code){
        that.zfbAuth(); //支付宝授权
      }
    },
    methods: {
      //支付宝授权
      zfbAuth(){
        let that = this;
        that.status = 2;
        axios({
          method: "post",
          url: 'https://paymanage.teamax-rj.com/prod-api/mer/channelIncome/zfb/exchangeZfbAuthToken?code='+that.app_auth_code+'&state='+that.state,
        }).then(function(response) {    
          var res = response.data;
          if (res.code == 200) {
            if(res.data == true){
              that.status = 1;
            }else{
              that.status = 0;
              Notify({ type: 'primary', message:  res.msg});
            }
          } else {
            that.status = 0;
            Notify({ type: 'primary', message:  res.msg});
          }
        })
      },
    }
  }
</script>
<style lang="scss">
  body{
    background-color: #ffffff;
  }
  .pages{
      height: 100%;
      padding: 10px 0;
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .header{
        text-align: center;
        .suc-logo{
          margin-top: 78px;
          width: 104px;
          height: 87px;
          margin-bottom: 41px;
        }
        .store-logo{
          margin-top: 65px;
          width: 106px;
          height: 106px;
          margin-bottom: 35px;
        }
        .load-err{
          margin-top: 66px;
          width: 361px;
          height: 326px;
          margin-bottom: 56px;
        }
        .status-text{
          font-size: 36px;
          font-weight: bold;
          color: #FA3474;
        }
        .load-text{
          font-size: 36px;
          font-weight: bold;
          color: #999999;
        }
      }
      .pay-info{
        text-align: center;
        margin-top: 155px;
        .store-title{
          font-size: 30px;
          font-weight: 400;
          color: #333333;
        }
        .pay-money{
          margin-top: 37px;
          font-size: 72px;
          font-weight: bold;
          color: #333333;
          .yuan{
            font-size: 30px;
            font-weight: 800;
          }
        }
      }
      .lookmore{
        margin-top: 30px;
        color: #4ea1db;
        text-decoration: underline;
      }
      .finish-btn{
        width: 270px;
        height: 68px;
        line-height: 68px;
        /* background: #F3F3F3; */
        border: 1px solid #FA3474;
        border-radius: 6px;
        color: #FA3474;
        text-align: center;
        font-size: 36px;
        position: absolute;
        bottom: 114px;
      }
      .req-bth{
        width: 360px;
        height: 68px;
        line-height: 68px;
        border: 1px solid #FA3474;
        border-radius: 6px;
        color: #FA3474;
        text-align: center;
        font-size: 36px;
        margin-top: 77px;
      }
  }
  
  .pay-loading{
    margin-top: 65px;
    width: 106px;
    height: 106px;
    margin-bottom: 35px;
    animation: rotate 1.5s infinite linear;
    -webkit-animation: rotate 1.5s infinite linear;
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes rotate {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @-moz-keyframes rotate {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(360deg);
    }
  }

  @-ms-keyframes rotate {
    from {
      -ms-transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
    }
  }

  @-o-keyframes rotate {
    from {
      -o-transform: rotate(0deg);
    }
    to {
      -o-transform: rotate(360deg);
    }
  }
</style>